<template>
  <div class="col-md-12 box my-2">
    <div class="row align-items-center h-100">
      <div class="col-md-5 d-flex align-items-center">
        <p class="descricao mb-0 mr-3">Material</p>
        <input type="text" class="input_default form-control" v-model="inventary.item" />
      </div>
      <div class="col-md-4 d-flex align-items-center">
        <p class="descricao mb-0 mr-3">Quantidade</p>
        <input type="text" class="input_default form-control" v-model="inventary.quantity" />
      </div>
      <div class="col-md-2 d-flex align-items-center justify-content-end ml-auto">
        <i class="fas fa-times-circle ml-4 exclude" @click.prevent="$emit('showObs')"></i>
        <i class="fas fa-check-circle ml-3 edit" @click.prevent="newLog()"></i>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";

export default {
  data() {
    return {
      inventary: { item: "", quantity: "" }
    };
  },
  mounted() {},
  methods: {
    newLog() {
      this.load = true;

      HTTP.post("services/app/Inventory/CreateInventory", this.inventary, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      }).then(response => {
        this.$emit("getInventay");
        this.$emit("showObs");
      });
    }
  }
};
</script>

<style scoped>
.box {
  background: #357eb8;
  border: 1px solid #e0a638;
  height: 75px;
}
.input_default {
  height: 45px;
  color: #ffffff;
  text-align: left;
  background-color: #6198c2 !important;
}
.descricao {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.fas,
.far {
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.exclude {
  color: #e67979;
}
.edit {
  color: #35b890;
}
</style>